import "chartjs-adapter-moment";

import {
  CategoryScale,
  Chart,
  Filler,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeScale,
  Tooltip,
} from "chart.js";
import React, { useEffect, useRef } from "react";

import { Line } from "react-chartjs-2";
// Import utilities
import { formatThousands } from "@/utils/utils";
import moment from "moment"

Chart.register(
  CategoryScale,
  LineController,
  LineElement,
  Filler,
  PointElement,
  LinearScale,
  TimeScale,
  Tooltip
);

const formatter = Intl.NumberFormat("en", {
  notation: "compact",
  compactDisplay: "short",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

const options1 = {
  type: "line",
  options: {
    layout: {
      padding: 20,
    },
    scales: {
      y: [
        {
          beginAtZero: true,
          border: {
            display: false,
          },
          ticks: {
            callback: (value) => {
              return formatter.format(value);
            },
          },
        },
      ],
      x: [
        {
          type: "time",
          time: {
            parser: "YYYY-MM-DD",
            unit: "month",
            displayFormats: {
              month: "MMM YY",
            },
          },
          border: {
            display: false,
          },
          grid: {
            display: false,
          },
          ticks: {
            autoSkipPadding: 48,
            maxRotation: 0,
          },
        },
      ],
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: () => false, // Disable tooltip title
          label: (context) => formatThousands(context.parsed.y),
        },
      },
    },
    interaction: {
      intersect: false,
      mode: "nearest",
    },
    maintainAspectRatio: false,
    resizeDelay: 200,
  },
};

function LineChart03({ data, width, height, fmtLabel }) {
  const options = {
    scales: {
      y: {
        border: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 10,
          callback: (value) => {
            return formatter.format(value);
          },
        },
      },
      x: {
        type: "time",
        time: {
          parser: "YYYY-MM-DD",
          unit: "day",
          displayFormats: {
            day: "MMM DD",
            week: "MMM DD",
            month: "MMM YYYY",
          },
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          autoSkipPadding: 48,
          maxRotation: 0,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          title: (context) => moment(context[0].parsed.x).format("MMM D, YYYY"),
          label: (context) => fmtLabel ? fmtLabel(context.parsed.y) : context.parsed.y,
        },
      },
    },
    interaction: {
      intersect: false,
      mode: "nearest",
    },
    maintainAspectRatio: false,
    resizeDelay: 200,
  };

  const plugins = {
    tooltip: {
      callbacks: {
        title: () => moment(context[0].parsed.x).format("MMM D, YYYY"), // Disable tooltip title
        label: (context) => fmtLabel ? fmtLabel(context.parsed.y) : formatThousands(context.parsed.y),
      },
    },
  };

  return (
    <Line
      data={data}
      options={options}
      width={width}
      height={height}
      // plugins={plugins}
    />
  );
}

export default LineChart03;
