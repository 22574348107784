export default function PercentageChange({ formatter, current, previous, style }) {
  const classNames = require("classnames");
  const valClass = style ? style : "mr-2 text-xl font-medium text-slate-800";
  const pctChange = (((current - previous) / previous) * 100).toFixed(0);

  const pctChangeClass = classNames("text-xs px-1.5 py-0.5 rounded-full", {
    "text-red-700": current - previous < 1,
    "text-emerald-500": current - previous > 0,
  });

  return (
    <>
      {previous > 0 && <div className={pctChangeClass}>{pctChange}%</div>}

      <div className={valClass}>
        {formatter.format(current)}
      </div>
    </>
  );
}
