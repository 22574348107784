import DataTable from "react-data-table-component";
import { tailwindConfig } from "@/utils/utils";

const customStyles = {
  head: {
    style: {
      color: tailwindConfig().theme.colors.slate[400],
      fontSize: 13,
      fontWeight: 500,
    },
  },
  headRow: {
    style: {
      backgroundColor: tailwindConfig().theme.colors.slate[50],
      borderBottomWidth: "1px",
      borderBottomColor: "#fff",
      borderBottomStyle: "solid",
    },
  },
  headCells: {
    style: {
      paddingLeft: "0.5rem",
      paddingRight: 0,
    },
  },
  rows: {
    style: {
      fontWeight: 500,
    },
  },
  cells: {
    style: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  }
};

export default function OptsiDatatable(props) {
  return <DataTable {...props} dense={true} customStyles={customStyles} />;
}
